import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'inv-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})



export class FooterComponent {
    @Input() fullWidth = false;
    currentYear = new Date().getFullYear();
}
