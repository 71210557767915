import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { SecurityService } from '../../services/security.service';
import { throwIfAlreadyLoaded } from '../module-import-guard';

// https://stackoverflow.com/questions/42695931/angular2-coremodule-vs-sharedmodule
//
// Create a CoreModule with providers for the singleton services you load when the application starts.
// Import CoreModule in the root AppModule only.
// Never import CoreModule in any other module.
// Consider making CoreModule a pure services module with no declarations.

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [SecurityService, HttpClientModule],
    declarations: [],
    exports: []
})
export class CoreModule {
    constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
    static forRoot(env): ModuleWithProviders {
        return {
            ngModule: CoreModule,
            providers: [{ provide: 'env', useValue: env }]
        };
    }
}
