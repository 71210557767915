import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserInfo } from './userInfo.model';

@Injectable()
export class SecurityService {
    public IsAuthorized: boolean;
    public HasAdminRole: boolean;
    public UserName: string;
    public ClientLevel: string;
    public LoginType: string;
    public RedirectUrl = '';
    private headers: HttpHeaders;
    private storage: any;
    private returnRoute: string;

    constructor(@Inject('env') private _environment,
        private _http: HttpClient,
        private _router: Router,
        private _location: LocationStrategy) {

        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Accept', 'application/json');
        this.storage = localStorage;

        if (this.retrieve('IsAuthorized') !== '') {

            this.HasAdminRole = this.retrieve('HasAdminRole');
            this.IsAuthorized = this.retrieve('IsAuthorized');
            this.UserName = this.retrieve('UserName');
            this.ClientLevel = this.retrieve('ClientLevel');
            this.LoginType = this.retrieve('LoginType');
        }

        if (!this.IsAuthorized && window.location.hash) {
            this.AuthorizedCallback();
          }
    }

    public GetToken(): any {
        const token = this.retrieve('authorizationData');
        return token;
    }

    public ResetAuthorizationData() {
        this.store('authorizationData', '');
        this.store('authorizationDataIdToken', '');

        this.IsAuthorized = false;
        this.HasAdminRole = false;
        this.UserName = '';
        this.ClientLevel = '';
        this.store('HasAdminRole', false);
        this.store('IsAuthorized', false);
        this.store('UserName', '');
        this.store('ClientLevel', '');
        this.store('LoginType', '');
    }

    public SetAuthorizationData(token: any, id_token: any) {
        try {

            if (this.retrieve('authorizationData') !== '') {
                this.store('authorizationData', '');
            }
            this.store('authorizationData', token);
            this.store('authorizationDataIdToken', id_token);
            this.IsAuthorized = true;
            this.store('IsAuthorized', true);

            const data: any = this.getDataFromToken(id_token);
            const loginType = data.idp;
            if (loginType === 'idsvr') {
                this.LoginType = 'Standard';
            } else {
                this.LoginType = loginType;
            }
            this.store('LoginType', this.LoginType);

            if (data.role) {
                for (let i = 0; i < data.role.length; i++) {
                    if (data.role[i] === 'admin') {
                        this.HasAdminRole = true;
                        this.store('HasAdminRole', true);
                    }
                }
            }
        } catch (e) {
            console.log('error:' + e);
        }
    }

    public setReturnRoute(route: string) {
        this.store('returnRoute', route);
    }

    public getReturnRoute() {
        return this.retrieve('returnRoute');
    }

    public SetUserInfo(token: any) {
        try {
            const userinfoUrl = this._environment.ssoUrl + 'connect/userinfo';
            const auth = 'Bearer ' + token;
            const httpOptions = {
                headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': auth
            })};
            this._http.get(userinfoUrl, httpOptions).pipe(
                map((res: UserInfo) => {
                    this.UserName = res.name;
                    this.store('UserName', this.UserName);
                }),
                catchError
                (error => {
                    console.error(error);
                    return Observable.throw(error.json().error || 'Server error');
                })).subscribe();
        } catch (e) {
            console.log('error:' + e);
        }
    }

    public Authorize(userName?: string, investecLogin?: string) {
        try {
            this.ResetAuthorizationData();
            console.log('BEGIN Authorize, no auth data');

            const authorizationUrl =  this._environment.ssoUrl + 'connect/authorize';
            const client_id = this._environment.clientId;
            const redirect_uri =   this._environment.redirectUrl;
            const response_type = 'id_token token';
            const scope = this._environment.scopes;
            const nonce = 'N' + Math.random() + '' + Date.now();
            const state = Date.now() + '' + Math.random();

            this.store('authStateControl', state);
            this.store('authNonce', nonce);
            console.log('AuthorizedController created. adding myautostate: '
                + this.retrieve('authStateControl'));

            let url =
                authorizationUrl + '?' +
                'response_type=' + encodeURI(response_type) + '&' +
                'client_id=' + encodeURI(client_id) + '&' +
                'redirect_uri=' + encodeURI(redirect_uri) + '&' +
                'scope=' + encodeURI(scope) + '&' +
                'nonce=' + encodeURI(nonce) + '&' +
                'state=' + encodeURI(state);

            if (userName) {
                url += `&login_hint=${userName}`;
            }

            if (investecLogin) {
                url += `&acr_values=idp:${investecLogin}`;
            }

            console.log('built and redirecting to authurl:' + url);
            window.location.href = url;

        } catch (e) {
            console.log(e);
        }
    }

    public AuthorizedCallback() {
        try {
            console.log('BEGIN AuthorizedCallback, no auth data');
            this.ResetAuthorizationData();
            const hash = window.location.hash.substr(1);
            const result: any = hash.split('&').reduce((mapHash, item) => {
                const parts = item.split('=');
                mapHash[parts[0]] = parts[1];
                return mapHash;
            }, {});

            console.log('AuthorizedCallback created, begin token validation');

            let token = '';
            let id_token = '';
            let authResponseIsValid = false;
            if (!result.error) {

                if (result.state !== this.retrieve('authStateControl')) {
                    console.log('AuthorizedCallback incorrect state');
                } else {

                    token = result.access_token;
                    id_token = result.id_token;

                    const dataIdToken: any = this.getDataFromToken(id_token);

                    // validate nonce
                    if (dataIdToken.nonce !== this.retrieve('authNonce')) {
                        console.log('AuthorizedCallback incorrect nonce');
                    } else {
                        this.store('authNonce', '');
                        this.store('authStateControl', '');

                        authResponseIsValid = true;
                        console.log(`AuthorizedCallback state and nonce validated, returning access token`);
                    }
                }
            }


            if (authResponseIsValid) {
                this.SetAuthorizationData(token, id_token);
                console.log(this.retrieve('authorizationData'));
                this.SetUserInfo(token);
                const returnUrl = this.retrieve('returnRoute');
                if (returnUrl) {
                    this.store('returnRoute', '');
                    this._router.navigateByUrl(returnUrl);
                } else {
                    this._router.navigate(['']);
                }
            } else {
                this.ResetAuthorizationData();
                this._router.navigate(['']);
            }
        } catch (e) {
            console.log(e);
        }
    }

    public Logoff() {
        console.log('BEGIN Authorize, no auth data');
        const authorizationUrl = this._environment.ssoUrl
            + 'connect/endsession';

        const id_token_hint = this.retrieve('authorizationDataIdToken');
        const post_logout_redirect_uri = this._environment.logoutUrl;

        const url =
            authorizationUrl + '?' +
            'id_token_hint=' + encodeURI(id_token_hint) + '&' +
            'post_logout_redirect_uri=' + encodeURI(post_logout_redirect_uri);

        this.ResetAuthorizationData();

        window.location.href = url;
    }

    public HandleError(error: any) {

        if (error.status === 403) {
            console.log('handle error forbidden:' + error);
            this._router.navigate(['/Forbidden']);
        } else if (error.status === 401) {
            console.log('handle error:' + error);
            this.ResetAuthorizationData();
            this._router.navigate(['']);
        }
    }

    private urlBase64Decode(str) {
        let output = str.replace('-', '+').replace('_', '/');
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += '==';
                break;
            case 3:
                output += '=';
                break;
            default:
                throw new Error('Illegal base64url string!');
        }

        return window.atob(output);
    }

    private getDataFromToken(token) {
        let data = {};
        if (typeof token !== 'undefined') {
            const encoded = token.split('.')[1];
            data = JSON.parse(this.urlBase64Decode(encoded));
        }

        return data;
    }

    private retrieve(key: string): any {
        const item = this.storage.getItem(key);

        if (item && item !== 'undefined') {
            return JSON.parse(this.storage.getItem(key));
        }

        return;
    }

    private store(key: string, value: any) {
        this.storage.setItem(key, JSON.stringify(value));
    }

}