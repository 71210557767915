import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SecurityService } from '../../services/security.service';

@Component({
    selector: 'inv-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Input() fullWidth = false; 
    @ViewChild('ootoImage', {static: true}) ootoImage: any;
    private sub: any;

    get userName() {
        return this._securityService.UserName;
    }

    get userLevel() {
        return this._securityService.ClientLevel;
    }

    constructor(
        private _securityService: SecurityService
    ) { }

    ngOnInit() { }

    signIn() {
        this._securityService.Authorize();
    }

    signOut() {
        this._securityService.Logoff();
    }

    isLoggedIn() {
        return this._securityService.IsAuthorized;
    }
}
